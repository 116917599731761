// Footer.js
import React from 'react';
import { Link, NavLink, BrowserRouter } from "react-router-dom";
import './footer.css';

const Footer = () => {
    const svgStyle = {
        height: "100%",
        width: "100%"
      };
      const pathStyle = {
        stroke: "none",
        fill: "#2663d4"
      };
  return (
    <footer id="footer">
    <section className="footer-content-box">
      <div className="footer-top">
        <div className="container">
          <div className="row">

            <div className="col-lg-5 col-md-5 col-sm-12 footer-info">
              <h3>S&S IT CONSULTANCY SERVICE LLP</h3>
              <p>SnS IT Services is incorporated in India. Since inception SnS trainings has a strong focus on the
                trainings
                in latest IT Technologies to serve IT Industries.
                Now boost your career with SnS most popular, latest and trending IT Courses. Get attractive offers, job
                opportunities, certification program, free demo session and much more.
              </p>
            </div>

            <div className="col-lg-2 offset-lg-3 col-md-3 offset-md-1 col-sm-6 col-xs-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><a href="#"><Link className="nav-link" to="/">Home</Link></a></li>
                
                <li><a href="#">About us</a></li>
                <li><a href="#"><Link className="nav-link" to='/career'>Career</Link></a></li>
                <li><a href="#"><Link className="nav-link" to='/training'>Training</Link></a></li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6 col-xs-6 footer-links">
              <h4>Admin</h4>
              <ul>
                <li><a href="#"><Link className="nav-link" to='/registrations'>Call Back List</Link></a></li>
                <li><a href="#"><Link className="nav-link" to='/postjob'>Post New Job</Link></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright <strong>SnS IT Services</strong>. All Rights Reserved
        </div>
      </div>
      <a href="https://wa.me/918247753228" className="whatsapp_float" target="_blank" rel="noopener noreferrer">
        <i className="fab fa-whatsapp"></i>
      </a>
    </section>
    <div className="wave">
      <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={svgStyle}>
        <path d="M-5.31,13.08 C310.93,105.09 244.68,-15.90 564.06,0.10 L500.00,150.00 L0.00,150.00 Z"
          style={pathStyle}></path>
      </svg>
    </div>
  </footer>
  );
};

export default Footer;