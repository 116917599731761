import { useEffect, useState } from "react";
import career_banner from "./../../assets/img/banner/career_banner.png";
import fresher from "./../../assets/img/career/fresher.svg";
import expericed from "./../../assets/img/career/expericed.svg";
import "./Career.scss";
import JobTile from "./JobTile";
import AOS from "aos";
import "aos/dist/aos.css";
import { TypeAnimation } from "react-type-animation";
// API
import { getJobs } from "./../../firebase/api";

const Career = () => {
  const [jobs, setJobs] = useState([]);
  useEffect(() => {
    AOS.init();
    getAllJobs();
  }, []);

  const getAllJobs = async () => {
    const allJobs = await getJobs();
    setJobs(allJobs);
  };

  const jobItems = jobs.map((job) => (
    // Correct! Key should be specified inside the array.
    <JobTile key={job.id.toString()} value={job} />
  ));

  return (
    <>
      <div className="career-banner">
        <img src={career_banner} alt="" />
        <h2 className="animate-charcter">
          <TypeAnimation
            cursor={false}
            sequence={[
              "Elevate",
              250,
              "Elevate Your",
              250,
              "Elevate Your Ambitions",
              1000,
              "Elevate Your Ambitions: Find",
              250,
              "Elevate Your Ambitions: Find, Apply",
              250,
              "Elevate Your Ambitions: Find, Apply & Succeed",
              250,
            ]}
            speed={1}
            style={{
              textAlign: "center",
            }}
            repeat={0}
          />
        </h2>
      </div>

      <section className="explore-opportunities">
        <div className="container">
          <h2>Explore opportunities</h2>
          <div className="explore-opportunities-types">
            <div className="opportunities-tile">
              <figure>
                <img src={fresher}/>
              </figure>
              <h4>Graduate</h4>
              <a href="#">Apply &nbsp;&nbsp;<i className="fa-solid fa-arrow-right animated"></i></a>
            </div>
            <div className="opportunities-tile">
              <figure>
                <img src={expericed}/>
              </figure>
              <h4>Experienced</h4>
              <a href="#">Apply &nbsp;&nbsp;<i className="fa-solid fa-arrow-right animated"></i></a>
            </div>
          </div>
        </div>
      </section>

      <div className="career-search pt-5">
        <div className="container">
          <form>
            <div className="row align-items-center">
              <div className="col-sm-5">
                <label className="sr-only" htmlFor="search">
                  Search
                </label>
                <input
                  type="text"
                  className="form-control mb-2"
                  id="search"
                  placeholder="Search jobs by skill or keyword"
                />
              </div>
              <div className="col-sm-5">
                <label className="sr-only" htmlFor="location">
                  Location
                </label>
                <input
                  type="text"
                  className="form-control mb-2"
                  id="location"
                  placeholder="Location"
                />
              </div>
              <div className="col-auto">
                <button type="submit" className="btn btn-primary mb-2">
                  Search
                </button>
              </div>
            </div>
          </form>
          <h5 className="pt-3">Latest Jobs:</h5>
          {/* <h6 className="text-center text-muted" >
            No Jobs Avialable at this moment
          </h6> */}
        </div>
      </div>
      <div className="container mt-2" data-aos="fade-down">
        <div className="row">{jobItems}</div>
      </div>
    </>
  );
};

export default Career;
