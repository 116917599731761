// Home.js
import React, { useEffect } from "react";
import "./home.scss";
import certified from "./../../assets/img/banner/certified.svg";
import HomeBanner from "./homeBanner/homeBanner";
import CorporateTraining from "./corporateTraining/CorporateTraining";
import SnSServicesSlider from "./snSServicesSlider/SnSServicesSlider";
import WhereToContactUs from "./../../views/where_to_contact/index";
import VisionMission from "./../../views/vision-mission/index";
import CallBack from "./../../views/contact-form";
import certifiedIcon from "./../../assets/img/icons/certified.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <HomeBanner></HomeBanner>
      <div className="container home-welcome pt-3">
        <h3 className="md-hide md-end-sm-center">
          Welcome to <span className="brand-txt">S&S Consultancy Service</span>
        </h3>
        <div className="row align-items-center">
          <div className="col-md-6 home-welcome-imgbox">
            <img src={certified} alt="We are ISO Certified Company" />
            <div className="org-certification">
              <img src={certifiedIcon} alt="" />
              <p className="lead">We are ISO Certified Company</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="second-right">
              <h3 className="sm-hide">
                Welcome to
                <span className="brand-txt">S&S Consultancy Service</span>
              </h3>
              <p>
                We embark on a journey defined by a compelling vision and a
                steadfast mission. Our vision is to stand as the trusted partner
                of choice for businesses, pioneering innovative and reliable IT
                solutions that serve as catalysts for growth. Our mission is
                centered on delivering nothing short of exceptional IT services
                and support. We pride ourselves on tailoring our solutions to
                meet the unique needs of each client. we address your specific
                challenges and provide customized IT solutions that seamlessly
                align with your goals, ensuring tangible and transformative
                results.
              </p>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="dot-indent">
                  <h4>IT Consulting service</h4>
                  <small>
                    We help companies to assess and align their technology
                    strategies.
                  </small>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="dot-indent">
                  <h4>Technical Training</h4>
                  <small>
                    Stay ahead of the curve with our industry-focused training
                    programs.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SnSServicesSlider></SnSServicesSlider>
      <VisionMission></VisionMission>
      <CorporateTraining></CorporateTraining>
      <CallBack></CallBack>
      <WhereToContactUs></WhereToContactUs>
    </div>
  );
};

export default Home;
