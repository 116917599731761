// api.js
import { getFirestore, collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";
import firebaseApp from "./firebase";

const db = getFirestore(firebaseApp);
const registeredUsersList = collection(db, "registeredUsers");
const jobs = collection(db, "jobs");

export const getUsers = async () => {
  const querySnapshot = await getDocs(registeredUsersList);
  let data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  console.log("dataatatata", data);
  return data;
};

export const addItem = async (user) => {
  await addDoc(registeredUsersList, user);
};

export const updateItem = async (id, updatedItem) => {
  const itemDoc = doc(db, "registeredUsers", id);
  await updateDoc(itemDoc, updatedItem);
};

export const deleteItem = async (id) => {
  const itemDoc = doc(db, "registeredUsers", id);
  await deleteDoc(itemDoc);
};


// Jobs
export const addJob = async (job) => {
  await addDoc(jobs, job);
};

export const getJobs = async () => {
  const querySnapshot = await getDocs(jobs);
  let data = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  return data;
};

export const deleteJob = async (id) => {
  const jobsDoc = doc(db, "jobs", id);
  await deleteDoc(jobsDoc);
};