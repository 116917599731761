import React from "react";
import it_consulting_services from "../../../assets/img/progress/it_consulting_services.svg";
import interview_training from "../../../assets/img/progress/interview_training.svg";
import adv_software_training from "../../../assets/img/progress/adv_software_training.svg";
import product_training from "../../../assets/img/progress/product_training.svg";
import social_media_development from "../../../assets/img/progress/social_media_development.svg";
import "./SnSServicesSlider.scss";

const SnSServicesSlider = () => {

  const svgStyle = {
    display: "block",
    width: "100%",
  };
  const pathStyle = {
    strokeDasharray: "251.363, 251.363",
    strokeDashoffset: 0,
  };

  return (
    <div className="progress-it-services section section--angled-light-blue-gradient">
      <div className="container">
        <h2 className="text-center pt-5 pb-2">Our Offered IT Services</h2>
        <div className="process">
          <div className="process__track">
            <div className="process__progress">
              <div className="process__progress-bar" id="progress">
                <svg viewBox="0 0 100 100" style={svgStyle}>
                  <path
                    d="M 50,50 m 0,-40 a 40,40 0 1 1 0,80 a 40,40 0 1 1 0,-80"
                    stroke="rgb(99,194,237)"
                    strokeWidth="20"
                    fillOpacity="0"
                    style={pathStyle}
                  ></path>
                </svg>
              </div>
              <span className="process__step-number is-active">1</span>
              <span className="process__step-number">2</span>
              <span className="process__step-number">3</span>
              <span className="process__step-number">4</span>
              <span className="process__step-number">5</span>
            </div>
          </div>

          <div className="process__steps">
            <div className="process-item process-item--is-active" id="IT_CONSULTING_SERVICES">
              <h4 className="process-item__title text-center md-hide">
                IT CONSULTING AND SERVICES
              </h4>
              <figure className="process-item__figure">
                <img
                  src={it_consulting_services}
                  alt="IT CONSULTING AND SERVICES"
                  className="process-item__image"
                />
              </figure>
              <div className="process-item__content">
                <h4 className="process-item__title sm-hide">
                  IT CONSULTING AND SERVICES
                </h4>
                <p className="process-item__description">
                  We help companies to assess and align their technology
                  strategies with their business or process strategies. improve
                  the performance, scalability and competitiveness of your
                  company through right technology enablement and usage.
                </p>
              </div>
            </div>
            <div className="process-item process-item--is-active" id="INTERVIEW_TRAINING">
              <h4 className="process-item__title text-center md-hide">
                IT CONSULTING AND SERVICES
              </h4>
              <figure className="process-item__figure">
                <img
                  src={interview_training}
                  alt="INTERVIEW TRAINING"
                  className="process-item__image"
                />
              </figure>
              <div className="process-item__content">
                <h4 className="process-item__title sm-hide">INTERVIEW TRAINING</h4>
                <p className="process-item__description">
                  Ace your interviews with confidence! Our interview preparation
                  services offer valuable insights, practice sessions, and
                  strategies to help you present your best self and secure those
                  coveted opportunities.
                </p>
              </div>
            </div>
            <div className="process-item process-item--is-active" id="ADVANCE_SOFTWARE_Training">
              <h4 className="process-item__title text-center md-hide">
                IT CONSULTING AND SERVICES
              </h4>
              <figure className="process-item__figure">
                <img
                  src={adv_software_training}
                  alt="ADVANCE SOFTWARE Training"
                  className="process-item__image"
                />
              </figure>
              <div className="process-item__content">
                <h4 className="process-item__title sm-hide">
                  ADVANCE SOFTWARE Training
                </h4>
                <p className="process-item__description">
                  Stay ahead of the curve with our industry-focused training
                  programs. Our carefully crafted courses cover a wide array of
                  in-demand skills, ensuring you're equipped with the knowledge
                  necessary to excel in your field.
                </p>
              </div>
            </div>
            <div className="process-item process-item--is-active" id="Product_Development">
              <h4 className="process-item__title text-center md-hide">
                IT CONSULTING AND SERVICES
              </h4>
              <figure className="process-item__figure">
                <img
                  src={product_training}
                  alt="Product Developmen"
                  className="process-item__image"
                />
              </figure>
              <div className="process-item__content">
                <h4 className="process-item__title sm-hide">Product Development</h4>
                <p className="process-item__description">
                  We're not just about personal growth; we're about fostering
                  innovation too. Our team is actively involved in diverse
                  product development initiatives, creating solutions that
                  address real-world challenges and make a positive impact.
                </p>
              </div>
            </div>
            <div className="process-item process-item--is-active" id="Social_Media_engagement">
              <h4 className="process-item__title text-center md-hide">
                IT CONSULTING AND SERVICES
              </h4>
              <figure className="process-item__figure">
                <img
                  src={social_media_development}
                  alt="testing of a website on a mobile device"
                  className="process-item__image"
                />
              </figure>
              <div className="process-item__content">
                <h4 className="process-item__title sm-hide">
                  Social Media engagement
                </h4>
                <p className="process-item__description">
                  We provide creative engaging content for social media involves
                  understanding your audience, being authentic, and delivering
                  value.
                </p>
                <h6>
                  Visual Content, Interactive Content, Educational Content
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

     
        <script src="../../../assets/js/animate-steps.js" type="text/javascript" />
      
    </div>
  );
};

export default SnSServicesSlider;
