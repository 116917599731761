import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "./Training.scss";

import trai_blck from "./../../assets/img/training/trai_blck.svg";
import trai_phy from "./../../assets/img/training/trai_phy.svg";
import trai_icon from "./../../assets/img/training/trai_icon.svg";
import trai_amazon from "./../../assets/img/training/trai_amazon.svg";

import design from "./../../assets/img/training/design.svg";
import development from "./../../assets/img/training/development.svg";
import marketing from "./../../assets/img/training/marketing.svg";
import language from "./../../assets/img/training/language.svg";
import ITser from "./../../assets/img/training/IT&ser.svg";
import personal_care from "./../../assets/img/training/personal-care.svg";
import business from "./../../assets/img/training/business.svg";
import music from "./../../assets/img/training/music.svg";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Training = () => {

  useEffect(() => {
    AOS.init();
  }, [])

  const options = {
    loop: true,
    margin: 20,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1400: {
        items: 5,
      },
    },
  };

  const trendingLectures = {
    loop: true,
    margin: 20,
    nav: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1400: {
        items: 5,
      },
    },
  };

  return (
    <>
      <div className="badge-background">
        <p>Expand your skills and knowledge with 100+ training lectures</p>
      </div>

      <div className="container pt-3 pb-3">
        <div className="row">
          <OwlCarousel
            className="owl-theme"
           {...options}
          >
            <div className="item">
              <figure>
                <img src={trai_blck} alt="Java" />
              </figure>
            </div>
            <div className="item">
              <figure>
                <img src={trai_phy} alt="Java" />
              </figure>
            </div>
            <div className="item">
              <figure>
                <img src={trai_icon} alt="Java" />
              </figure>
            </div>
            <div className="item">
              <figure>
                <img src={trai_amazon} alt="Java" />
              </figure>
            </div>
            <div className="item">
              <figure>
                <img src={trai_blck} alt="Java" />
              </figure>
            </div>
            <div className="item">
              <figure>
                <img src={trai_phy} alt="Java" />
              </figure>
            </div>
            <div className="item">
              <figure>
                <img src={trai_icon} alt="Java" />
              </figure>
            </div>
            <div className="item">
              <figure>
                <img src={trai_amazon} alt="Java" />
              </figure>
            </div>
          </OwlCarousel>
        </div>
      </div>

      <section className="container mb-5">
        <h3>Most trending lectures:</h3>
        <div className="row">
          <OwlCarousel
            className="owlNavCenter"
           {...trendingLectures}
          >
            <div className="item">
              <figure>
                <img src={trai_blck} alt="Java" />
              </figure>
              <div className="d-flex justify-content-between mb-1">
                <h6 className="mb-0">Dot Net</h6>
                <span className="badge badge-success">Free</span>
              </div>
              <small>Suneel Vanka</small>
              <p className="rating">
                <span className="heading">4 <span className="fa fa-star checked"></span></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
              </p>
            </div>
            <div className="item">
              <figure>
                <img src={trai_phy} alt="Java" />
              </figure>
              <div className="d-flex justify-content-between mb-1">
                <h6 className="mb-0">Architecture</h6>
                <span className="badge badge-success">Free</span>
              </div>
              <small>Shankar</small>
              <p className="rating">
                <span className="heading">5 <span className="fa fa-star checked"></span></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
              </p>
            </div>
            <div className="item">
              <figure>
                <img src={trai_icon} alt="Java" />
              </figure>
              <div className="d-flex justify-content-between mb-1">
                <h6 className="mb-0">Recruitement</h6>
                <span className="badge badge-success">Free</span>
              </div>
              <small>Vidyesha</small>
              <p className="rating">
                <span className="heading">4 <span className="fa fa-star checked"></span></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
              </p>
            </div>
            <div className="item">
              <figure>
                <img src={trai_amazon} alt="Java" />
              </figure>
              <div className="d-flex justify-content-between mb-1">
                <h6 className="mb-0">Node JS</h6>
                <span className="badge badge-success">Free</span>
              </div>
              <small>Sagar</small>
              <p className="rating">
                <span className="heading">4 <span className="fa fa-star checked"></span></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
              </p>
            </div>
            <div className="item">
              <figure>
                <img src={trai_blck} alt="Java" />
              </figure>
              <div className="d-flex justify-content-between mb-1">
                <h6 className="mb-0">UI</h6>
                <span className="badge badge-success">Free</span>
              </div>
              <small>Pruthvi</small>
              <p className="rating">
                <span className="heading">4 <span className="fa fa-star checked"></span></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
              </p>
            </div>
            <div className="item">
              <figure>
                <img src={trai_phy} alt="Java" />
              </figure>
              <div className="d-flex justify-content-between mb-1">
                <h6 className="mb-0">Java</h6>
                <span className="badge badge-success">Free</span>
              </div>
              <small>Venkata Rao</small>
              <p className="rating">
                <span className="heading">3 <span className="fa fa-star checked"></span></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
                <span className="fa fa-star"></span>
              </p>
            </div>
            <div className="item">
              <figure>
                <img src={trai_icon} alt="Java" />
              </figure>
              <div className="d-flex justify-content-between mb-1">
                <h6 className="mb-0">MERN</h6>
                <span className="badge badge-success">Free</span>
              </div>
              <small>Seshu</small>
              <p className="rating">
                <span className="heading">4 <span className="fa fa-star checked"></span></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star"></span>
              </p>
            </div>
            <div className="item">
              <figure>
                <img src={trai_amazon} alt="trai amazon" />
              </figure>
              <div className="d-flex justify-content-between mb-1">
                <h6 className="mb-0">Soft Skills</h6>
                <span className="badge badge-success">Free</span>
              </div>
              <small>Sachin</small>
              <p className="rating">
                <span className="heading">5 <span className="fa fa-star checked"></span></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
                <span className="fa fa-star checked"></span>
              </p>
            </div>
          </OwlCarousel>
        </div>
      </section>

      <section className="container">
        <h3 data-aos="fade-down">Top Searched Categories:</h3>
        <div className="top-seached-category-wrap text-center" data-aos="fade-down">
          <a href="#" className="top-seached-category">
            <figure>
              <img src={design} alt="Design" />
              <figcaption>Design</figcaption>
            </figure>
          </a>
          <a href="#" className="top-seached-category">
            <figure>
              <img src={development} alt="Development" />
              <figcaption>Development</figcaption>
            </figure>
          </a>
          <a href="#" className="top-seached-category">
            <figure>
              <img src={marketing} alt="marketing" />
              <figcaption>Marketing</figcaption>
            </figure>
          </a>
          <a href="#" className="top-seached-category">
            <figure>
              <img src={language} alt="language" />
              <figcaption>Language</figcaption>
            </figure>
          </a>
          <a href="#" className="top-seached-category">
            <figure>
              <img src={ITser} alt="IT&Software" />
              <figcaption>IT&Software</figcaption>
            </figure>
          </a>
          <a href="#" className="top-seached-category">
            <figure>
              <img src={personal_care} alt="personal-care" />
              <figcaption>Personal Care</figcaption>
            </figure>
          </a>
          <a href="#" className="top-seached-category">
            <figure>
              <img src={business} alt="Business" />
              <figcaption>Business</figcaption>
            </figure>
          </a>
          <a href="#" className="top-seached-category">
            <figure>
              <img src={music} alt="Music" />
              <figcaption>Music</figcaption>
            </figure>
          </a>
        </div>
      </section>
    </>
  );
};

export default Training;
