import React, { useEffect } from "react";
import "./index.scss";
import AOS from "aos";
import "aos/dist/aos.css";

const WhereToContactUs = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <section className="home-contact-us">
        <div className="container">
          <h3 className="text-center pt-5 pb-4" data-aos="fade-down">Where to contact us</h3>
          <div className="contact-thumbs" data-aos="fade-down">
            <a  
              href="https://www.facebook.com/profile.php?id=100088891546858"
              target="_blank"
              className="org-social-link"
            >
              <i className="fa-brands fa-facebook"></i>
              S&S It Consultancy
            </a>
            <a
              href="https://www.linkedin.com/company/sns-it-software-solutions-llp/?viewAsMember=true"
              target="_blank"
              className="org-social-link"
            >
              <i className="fa-brands fa-linkedin"></i>
              S&S IT Software Solutions
            </a>
            <a href="#" className="org-social-link" target="_blank">
              <i className="fa-brands fa-square-instagram"></i>
              S&S IT Software Solutions
            </a>
            <a href="#" className="org-social-link">
              <i className="fa-brands fa-square-twitter"></i>
              S&S IT Software Solutions
            </a>

            <a href="#" className="org-social-link">
              <i className="fa-solid fa-globe"></i>
              S&S IT Software Solutions
            </a>
            <a href="#" className="org-social-link border-w-100">
              <i className="fa-solid fa-location-dot"></i>
              Hyderabad, Telangana
            </a>
            <a href="#" className="org-social-link border-w-100">
              <i className="fa-solid fa-phone"></i>
              +91 8977012129
            </a>
            <span className="org-social-link">
              <i className="fa-solid fa-envelope"></i>
              <a href="hr@snsitservice.com">hr@snsitservice.com</a> /
              <a href="snsitinfoservice@gmail.com">
                snsitinfoservice@gmail.com
              </a>
            </span>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhereToContactUs;
