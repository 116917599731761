// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAoH2sjxpVH28G8KpXrun_MW8SDDKjz0-k",
  authDomain: "snsitservice.firebaseapp.com",
  projectId: "snsitservice",
  storageBucket: "snsitservice.appspot.com",
  messagingSenderId: "433270899517",
  appId: "1:433270899517:web:ebe4a7e591fa8368ca3b54",
  measurementId: "G-82LXF817K0"
};

// Initialize Firebase
const firebaseApp  = initializeApp(firebaseConfig);
export default firebaseApp;