import { useEffect, useState } from "react";
import "./registeredUsers.scss";
import { getUsers, deleteItem } from "./../../firebase/api";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const RegisteredUsers = () => {
  const [users, setUsers] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteUserId, setdeleteUserId] = useState("");
  const [deletableUserName, setDeletableUserName] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getRegistredUsersList();
  }, []);

  const getRegistredUsersList = async () => {
    const usersList = await getUsers();
    console.log("usersList", usersList);
    setUsers(usersList);
  };

  const deleteUser = (user) => {
    setErrorMsg("");
    setdeleteUserId(user.id);
    setDeletableUserName(`${user.firstName} ${user.lastName}`);
    handleShow();
  };

  const deleteConfirmation = async () => {
    try {
      await deleteItem(deleteUserId);
      let updatedUsersList = removeItemById(deleteUserId);
      setUsers(updatedUsersList);
      handleClose();
    } catch (e) {
      setErrorMsg("Something went Wrong");
    }
  };

  const removeItemById = (id) => {
    return users.filter((item) => item.id !== id);
  };

  return (
    <>
      <div className="registeredUsers">
        <div className="container">
          <h2 className="text-left">Registered Users</h2>
          {errorMsg && (
            <div className="alert alert-danger" role="alert">
              {errorMsg}
            </div>
          )}
          <table className="table table-striped">
            <thead className="thead-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">First Name</th>
                <th scope="col">Last Name</th>
                <th scope="col">Phone</th>
                <th scope="col">Email</th>
                <th scope="col">Message</th>
                <th scope="col">Channel</th>
                <th scope="col" className="text-center"></th>
              </tr>
            </thead>
            <tbody>
              {users?.map((user, index) => (
                <tr key={user.id}>
                  <td scope="row">{index + 1}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>{user.phoneNumber}</td>
                  <td>{user.email}</td>
                  <td>{user.description}</td>
                  <td>{user.where}</td>
                  <td className="text-center">
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => deleteUser(user)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are You Sure, You Want to Delete <strong>{deletableUserName}</strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={deleteConfirmation}>
            Yes
          </Button>
          <Button variant="primary" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RegisteredUsers;
