import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const MoreDetails = ({ value, closeEvent }) => {
  let job = value;
  let closeMoreDetails = closeEvent;
  const handleClose = () => {
    closeMoreDetails();
  };

  const contact = () => {
    console.log("ASSDASD");
  };

  return (
    <>
      <Modal show={true} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{job.jobTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-1 description">
            <strong>Category: </strong>{job.category}
          </p>
          <p className="mb-1 description">
            <strong>Min. Req. Experience:</strong> {job.yearsOfExp}
          </p>
          <p className="mb-1 description">
            <strong>Budget:</strong> {job.budget}
          </p>
          <p className="mb-3 description">
            <strong>Work Mode:</strong> {job.workMode}
          </p>
          <p className="mb-3 description">
            <strong>Location:</strong> {job.location}
          </p>
          <p className="mb-3 description">
            <strong>Required Skills:</strong> {job.requiredSkills}
          </p>

          <p className="mb-3 description">
            <strong>Roles and Responsibilities:</strong> {job.rolesAndRes}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={contact}>
            Contact
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MoreDetails;
