import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "./homeBanner.scss";
import banner from "./../../../assets/img/home-banner.svg";
import bannerJPEG from "./../../../assets/img/home-banner.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { TypeAnimation } from "react-type-animation";

const HomeBanner = () => {
  const options = {
    loop: true,
    margin: 0,
    dots: false,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="homeBanner">
        <OwlCarousel className="owl-theme" {...options}>
          <div className="item">
            <figure>
              <img src={bannerJPEG} alt="" />
            </figure>
            <div className="txtAnimations">
              <div data-aos="fade-down">
                <h3>Welcome to S&S IT Consultancy</h3>
                <h4>
                  Where Innovation Meets Implementation: Your IT Consultancy
                  Partner.
                </h4>
              </div>
            </div>
          </div>

          <div className="item">
            <figure>
              <img src={bannerJPEG} alt="" />
            </figure>
            <div className="txtAnimations">
              <div data-aos="fade-down">
                <h3>IT Consulting Service</h3>
                <h4>
                  Guiding Success, Empowering Futures: Your strategic Consulting
                  Partner
                </h4>
              </div>
            </div>
          </div>

          <div className="item">
            <figure>
              <img src={bannerJPEG} alt="" />
            </figure>
            <div className="txtAnimations">
              <div data-aos="fade-down">
                <h3>Training &nbsp; & Support</h3>
                <h4>Empowering success through comprehensive training and unwavering support</h4>
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </>
  );
};

export default HomeBanner;
