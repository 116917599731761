import React from "react";
import "./frame.css";
import FrameImage1 from "../../../assets/img/banner/frame-img1.svg";
import FrameImage2 from "../../../assets/img/banner/frame-img2.svg";
import FrameImage3 from "../../../assets/img/banner/frame-img3.svg";
import FrameImage4 from "../../../assets/img/banner/frame-img4.svg";

const Frames = () => {
  return (
    <>
      <div className="about-stories">
        <div>
          <img src={FrameImage1} />
        </div>
        <div className="frame-description">
          <p>Crafting Success Stories: Unleash the Potential of Our Services</p>
        </div>
        <div>
          <img src={FrameImage2} />
        </div>
        <div className="frame-description">
          <p>Precision Crafted Services for a Seamless Business Experience</p>
        </div>

        <div className="frame-description">
          <p>
            Providing Innovative Services for a Digital Era: Powering Your
            Progress
          </p>
        </div>

        <div>
          <img src={FrameImage3} />
        </div>

        <div className="frame-description">
          <p>
            From Concept to Reality: Transformative Services at Your Fingertips
          </p>
        </div>

        <div>
          <img src={FrameImage4} />
        </div>
      </div>
    </>
  );
};

export default Frames;
