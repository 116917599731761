// Home.js
import React, { useEffect } from "react";
import "./index.scss";
import vision from "./../../assets/img/banner/vision.png";
import mission from "./../../assets/img/banner/mission.png";
import AOS from "aos";
import "aos/dist/aos.css";

const VisionMission = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="container mission-vision">
      <h3 className="md-hide text-center" data-aos="fade-down">VISION</h3>
      <div className="row align-items-center">
        <div className="col-lg-5">
          <img src={vision} alt="Vision" />
        </div>
        <div className="col-lg-7">
          <h3 className="sm-hide">VISION</h3>
          <p>
            Our vision is to be the trusted partner of choice for businesses,
            offering innovative and reliable IT solutions that empower growth,
            elevate productivity, and streamline operations. We aspire to create
            a future where businesses can fully harness the potential of IT to
            achieve their objectives and maintain a competitive edge in an
            ever-evolving digital landscape.
          </p>
        </div>
      </div>
      <h3 className="md-hide text-center pt-4">MISSION</h3>
      <div className="row align-items-center">
        <div className="col-lg-7 text-end pr-3">
          <h3 className="sm-hide" >MISSION</h3>
          <p className="sm-hide">
            Our mission is to deliver exceptional IT services and support that
            are precisely tailored to meet the unique needs of each client. We
            are dedicated to cultivating long-lasting partnerships built on
            trust, transparency, and outstanding customer service. By deeply
            comprehending your business objectives and addressing your specific
            challenges, we provide customized IT solutions that align seamlessly
            with your goals, ultimately delivering tangible and transformative
            results.
          </p>
        </div>
        <div className="col-lg-5 md-text-end">
          <img src={mission} alt="Mission" />
          <p className="md-hide text-center pt-3">
            Our mission is to deliver exceptional IT services and support that
            are precisely tailored to meet the unique needs of each client. We
            are dedicated to cultivating long-lasting partnerships built on
            trust, transparency, and outstanding customer service. By deeply
            comprehending your business objectives and addressing your specific
            challenges, we provide customized IT solutions that align seamlessly
            with your goals, ultimately delivering tangible and transformative
            results.
          </p>
        </div>
      </div>
    </div>
  );
};

export default VisionMission;
