import React, { useEffect, useState } from "react";
import "./index.scss";
import contact from "./../../assets/img/contact.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { addItem, getUsers } from "../../firebase/api";

const CallBack = () => {
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const [userExistsMsg, setUserExistsMsg] = useState("");
  const [registeredUser, setRegisteredUser] = useState({});

  useEffect(() => {
    AOS.init();
    const user = JSON.parse(localStorage.getItem("registeredUser"));
    if (user && Object.keys(user).length > 0) {
      setRegisteredUser(user);
    }
  }, []);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    description: "",
    where: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    description: "",
    where: "",
  });

  const handleChange = (e) => {
    setUserExistsMsg("");
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const validateForm = () => {
    let valid = true;
    setUserExistsMsg("");
    const newErrors = {};

    // Validation for first name
    if (!formData.firstName.trim()) {
      valid = false;
      newErrors.firstName = "First name is required";
    }

    // Validation for last name
    if (!formData.lastName.trim()) {
      valid = false;
      newErrors.lastName = "Last name is required";
    }

    // Validation for phone number
    const phoneRegex = /^\d{10}$/;
    if (!formData.phoneNumber.match(phoneRegex)) {
      valid = false;
      newErrors.phoneNumber = "Enter a valid 10-digit phone number";
    }

    // Validation for email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.match(emailRegex)) {
      valid = false;
      newErrors.email = "Enter a valid email address";
    }

    // Validation for description
    if (!formData.description.trim()) {
      valid = false;
      newErrors.description = "Description is required";
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Perform action with the form data (e.g., submit to server)
      console.log("Form submitted:", formData);
      if (!checkDuplicateUser(formData)) {
        addItem(formData);
        setDataSubmitted(true);
      } else {
        setDataSubmitted(false);
      }
    } else {
      console.log("Form has errors. Please correct them.");
    }
  };

  const checkDuplicateUser = async (formData) => {
    setUserExistsMsg("");
    let isDuplicate = false;
    const usersList = await getUsers();
    const email = formData.email.trim();
    const phone = formData.phoneNumber.trim();
    for (const user of usersList) {
      if (user.email.trim() === email) {
        setUserExistsMsg(`Email '${email}' already exists for ${user.firstName} ${user.lastName}`);
        isDuplicate = true;
        return;
      } else if (user.phoneNumber.trim() === phone) {
        setUserExistsMsg(`Phone number '${phone}' already exists for ${user.firstName} ${user.lastName}`);
        isDuplicate = true;
        return;
      }
    }
    return isDuplicate;
  };

  return (
    <>
      <div className="home-call-back">
        <div className="container">
          <h3 className="text-center" data-aos="fade-down">
            Receive a call back !
          </h3>
          <div className="row align-items-stretch no-gutters contact-wrap">
            {!dataSubmitted && (
              <div className="col-sm-8">
                <div className="col-md-12">
                  <div className="form h-100">
                  {userExistsMsg && (
                    <div className="alert alert-danger" role="alert">
                      {userExistsMsg}
                    </div>
                  )}
                    <form
                      onSubmit={handleSubmit}
                      className="mb-2"
                      method="post"
                      id="contactForm"
                      name="contactForm"
                    >
                      <div className="row">
                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="firstName" className="col-form-label">
                            First Name *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            id="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                          />
                          <div className="error">{errors.firstName}</div>
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="lastName" className="col-form-label">
                            Last Name *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            id="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                          />
                          <div className="error">{errors.lastName}</div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 form-group mb-3">
                          <label
                            htmlFor="phoneNumber"
                            className="col-form-label"
                          >
                            Phone *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="phoneNumber"
                            id="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                          />
                          <div className="error">{errors.phoneNumber}</div>
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="email" className="col-form-label">
                            Email *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            id="email"
                            value={formData.email}
                            onChange={handleChange}
                          />
                          <div className="error">{errors.email}</div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 form-group mb-3">
                          <label
                            htmlFor="where"
                            className="col-form-label d-block"
                          >
                            From where did you got to know about us?
                          </label>
                          <select
                            className="custom-select w-100"
                            id="where"
                            name="where"
                            value={formData.where}
                            onChange={handleChange}
                          >
                            <option defaultValue>Choose...</option>
                            <option value="socialmedia">Social Media</option>
                            <option value="searchengine">Search Engine</option>
                            <option value="email">Email</option>
                            <option value="wordofmouth">Word of Mouth</option>
                            <option value="other">Other</option>
                          </select>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 form-group mb-3">
                          <label
                            htmlFor="description"
                            className="col-form-label"
                          >
                            Message *
                          </label>
                          <textarea
                            className="form-control"
                            name="description"
                            id="description"
                            placeholder="Write your message"
                            value={formData.description}
                            onChange={handleChange}
                          ></textarea>
                          <div className="error">{errors.description}</div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <input
                            type="submit"
                            value="Register Now"
                            className="btn btn-primary rounded-0 py-2 px-4"
                            disabled={userExistsMsg.length > 0}
                          />
                          <span className="submitting"></span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            {dataSubmitted && (
              <div className="col-sm-8 d-flex align-items-center justify-content-center form-message-success">
                <p>
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </p>
                <p>
                  {" "}
                  &nbsp;&nbsp; You have already submitted your details. You will
                  receive a callback soon.
                </p>
              </div>
            )}
            <div className="col-sm-4 md-end-sm-center d-flex justify-content-end">
              <img src={contact} alt="Contact image" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallBack;
