import './App.css';
// App.js
import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Career from './pages/career/Career';
import Services from './pages/services/Services';
import Training from './pages/training/Training';
import RegisteredUsers from './pages/registeredUsers/registeredUsers';
import PostJob from './pages/jobPost/postJob';


const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/services" exact element={<Services />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/career" exact element={<Career />} />
        <Route path="/training" exact element={<Training />} />
        <Route path="/registrations" exact element={<RegisteredUsers />} />
        <Route path="/postJob" exact element={<PostJob />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
