import React from "react";
import "./About.scss";
import about from "../../assets/img/banner/about.svg";
import systemIcon from "../../assets/img/banner/slider-icons/system-icon.svg";
import cloudIcon from "../../assets/img/banner/slider-icons/cloud-icon.svg";
import handsIcon from "../../assets/img/banner/slider-icons/hands-icon.svg";
import classRoom from "../../assets/img/banner/slider-icons/classroom-icon.svg";
import Frames from "./frame/frame";
import OwlCarousel from "react-owl-carousel";
import WhereToContactUs from "./../../views/where_to_contact/index";
import VisionMission from "./../../views/vision-mission/index";
import isoStar from "../../assets/img/banner/star.svg";

const About = () => {

  const options = {
    loop: true,
    margin: 25,
    nav: false,
    dots:true,
    autoplay: true,
    autoplayTimeout: 10000,
    autoplayHoverPause: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1400: {
        items: 4,
      },
    },
  };

  return (
    <>
      <div className="about-banner">
        <img className="about-banner-img" src={about} alt="" />
        <div className="bannerTitle">
          <div className="about-banner-text">
            <h2 className="text-center">Welcome to S&S IT Consultancy</h2>
            <p className="text-center">
              Where Innovation Meets Implementation: Your IT Consultancy
              Partner.
            </p>
          </div>
        </div>
        <figure className="org-certification">
          <img src={isoStar} />
          <caption>ISO Certified Company</caption>
        </figure>
      </div>

      <section className="home-call-back pt-3">
        <div className="container">
          <h3 className="text-center">Who we are?</h3>
          <p>
            We embark on a journey defined by a compelling vision and a
            steadfast mission. Our vision is to stand as the trusted partner of
            choice for businesses, pioneering innovative and reliable IT
            solutions that serve as catalysts for growth. Our mission is
            centered on delivering nothing short of exceptional IT services and
            support. We pride ourselves on tailoring our solutions to meet the
            unique needs of each client. we address your specific challenges and
            provide customized IT solutions that seamlessly align with your
            goals, ensuring tangible and transformative results.
          </p>
        </div>
      </section>
      <div className="container mission-vision mb-4">
        <Frames></Frames>
      </div>
      <VisionMission></VisionMission>
      <section className="pt-3 offeredServices">
        <div className="container">
          <h3 className="text-center mb-4">Our Offered IT Services</h3>
        </div>
        <div className="container">
          <div className="row">
            <OwlCarousel
              className="owl-theme"
              {...options}
            >
              <div className="item">
                <figure>
                  <img className="vector" src={systemIcon} />
                </figure>
                <h5 className="text-center">IT Consulting And Services</h5>
                <p>
                  We help companies to assess and align their technology
                  strategies with their business or process strategies. improve
                  the performance, scalability and competitiveness of your
                  company through right technology enablement and usage.
                </p>
              </div>
              <div className="item">
                <figure>
                  <img className="vector" src={cloudIcon} />
                </figure>
                <h5 className="text-center">Cloud Solutions</h5>
                <p>
                  Unlock the power of the cloud with our comprehensive cloud
                  services. We assist businesses in migrating their data and
                  applications to secure and scalable cloud platforms. Whether
                  you need help with cloud strategy, deployment, or ongoing
                  management, we have the expertise to support your cloud
                  journey
                </p>
              </div>
              <div className="item">
                <figure>
                  <img className="vector" src={handsIcon} />
                </figure>
                <h5 className="text-center">It Technical Support</h5>
                <p>
                  Struggling to land on your dream job? Our dedicated team of
                  career experts is here to provide personalized guidance and
                  support. From resume optimization to mock interviews, we'll
                  equip you with the tools you need to stand out in a
                  competitive job market.
                </p>
              </div>
              <div className="item">
                <figure>
                  <img className="vector" src={classRoom} />
                </figure>
                <h5 className="text-center">Advance Software Training</h5>
                <p className="div">
                  Stay ahead of the curve with our industry-focused training
                  programs. Our carefully crafted courses cover a wide array of
                  in-demand skills, ensuring you're equipped with the knowledge
                  necessary to excel in your field.
                </p>
              </div>
            </OwlCarousel>
          </div>
        </div>
        <WhereToContactUs></WhereToContactUs>
      </section>
    </>
  );
};

export default About;
