import { useEffect, useState } from "react";
import "./Career.scss";
import MoreDetails from "./moreDetails";

const JobTile = (props) => {
  let job = props.value;
  const [showMore, setShowMore] = useState(false);
  const showMoreDetails = () => {
    setShowMore(true);
  }
  const closeMoreDetails = () => {
    setShowMore(false);
  }

  return (
    <>
      <div className="col-md-4 mb-4">
        <div className="card job-card">
          <div className="card-body">
            <div className="d-flex justify-content-between mb-3">
              <h6 className="country">{job.location}</h6>
              <div className="bookmark">
                <i className="fa-regular fa-bookmark"></i> Save
              </div>
            </div>
            <h5 className="card-title mb-2">{job.jobTitle}</h5>
            <p className="mb-1 description">
              <small>Category: {job.category}</small>
            </p>
            <p className="mb-1 description">
              <small>Min. Req. Experience: {job.yearsOfExp}</small>
            </p>
            <p className="mb-1 description">
              <small>Budget: {job.budget}</small>
            </p>
            <p className="mb-3 description">
              <small>Work Mode: {job.workMode}</small>
            </p>
            <div className="d-flex justify-content-center">
              <button className="btn btn-primary" onClick={showMoreDetails}>View More</button>
              <button className="btn btn-primary ms-4 disabled">
                Contact Us
              </button>
            </div>
          </div>
        </div>
      </div>
      {showMore && <MoreDetails value={job} closeEvent={closeMoreDetails}></MoreDetails>}
    </>
  );
};

export default JobTile;
