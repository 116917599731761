import React, { useState } from "react";
import { useFormik } from "formik";
import "./postJob.scss";

import { addJob } from "./../../firebase/api";

const PostJob = () => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const jobForm = useFormik({
    initialValues: {
      jobTitle: "",
      category: "",
      yearsOfExp: "",
      workMode: "",
      budget: "",
      location: "",
      country: "",
      requiredSkills: "",
      rolesAndRes: "",
    },
    validate,
    onSubmit: (values) => {
      const saveObject = trimObjectValues(values);
      setSuccess("");
      setError("");
      let errors = validate(saveObject);
      if (Object.keys(errors).length === 0) {
        saveNewJob(saveObject);
      } else {
        setError("Seems Entered details are invalid, Please correct and save");
      }
    },
  });

  const saveNewJob = (values) => {
    try {
      values.time = new Date().getTime();
      addJob(values);
      setSuccess("New Job Saved Successfully");
      jobForm.resetForm();
    } catch (e) {
      setError(
        "Something went wrong. Please validae Job Details and save again"
      );
    }
  };

  function trimObjectValues(obj) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // Check if the value is a string before trimming
        if (typeof obj[key] === "string") {
          obj[key] = obj[key].trim();
        }
      }
    }
    return obj;
  }

  const closeSuccess = () => {
    setSuccess("");
  };

  const closeError = () => {
    setError("");
  };

  return (
    <>
      <div>
        <div className="animated-container">
          <h3 className="text-center animate-charcter">Post a New Job</h3>

          <div className="bird-container bird-container--one">
            <div className="bird bird--one"></div>
          </div>

          <div className="bird-container bird-container--two">
            <div className="bird bird--two"></div>
          </div>

          <div className="bird-container bird-container--three">
            <div className="bird bird--three"></div>
          </div>

          <div className="bird-container bird-container--four">
            <div className="bird bird--four"></div>
          </div>
        </div>
        <div className="container home-call-back">
          <div className="row align-items-stretch no-gutters contact-wrap">
            <div className="col-sm-8">
              <div className="col-md-12">
                <div className="form h-100">
                  <h5>Fill New Job Details: </h5>
                  {success && (
                    <div className="alert alert-success" role="alert">
                      {success}
                      <button
                        type="button"
                        className="close btn"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={closeSuccess}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  )}

                  {error && (
                    <div className="alert alert-danger" role="alert">
                      {error}
                      <button
                        type="button"
                        className="close btn"
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={closeError}
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  )}
                  <form onSubmit={jobForm.handleSubmit} className="mb-2">
                    <div className="row">
                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="jobTitle" className="col-form-label">
                          Job Title *
                        </label>
                        <input
                          type="text"
                          maxLength={50}
                          className="form-control"
                          id="jobTitle"
                          name="jobTitle"
                          onChange={jobForm.handleChange}
                          onBlur={jobForm.handleBlur}
                          value={jobForm.values.jobTitle}
                        />
                        {jobForm.touched.jobTitle && jobForm.errors.jobTitle ? (
                          <div className="error">{jobForm.errors.jobTitle}</div>
                        ) : null}
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="category" className="col-form-label">
                          Category
                        </label>
                        <input
                          type="text"
                          maxLength={50}
                          className="form-control"
                          id="category"
                          name="category"
                          onChange={jobForm.handleChange}
                          onBlur={jobForm.handleBlur}
                          value={jobForm.values.category}
                        />
                        {jobForm.touched.category && jobForm.errors.category ? (
                          <div className="error">{jobForm.errors.category}</div>
                        ) : null}
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label
                          htmlFor="yearsOfExp"
                          className="col-form-label d-block"
                        >
                          Minimum Years of Experience*
                        </label>
                        <select
                          className="custom-select w-100"
                          id="yearsOfExp"
                          name="yearsOfExp"
                          value={jobForm.values.yearsOfExp}
                          onChange={jobForm.handleChange}
                        >
                          <option defaultValue="">Choose...</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                        </select>
                        {jobForm.touched.yearsOfExp &&
                        jobForm.errors.yearsOfExp ? (
                          <div className="error">
                            {jobForm.errors.yearsOfExp}
                          </div>
                        ) : null}
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="workMode" className="col-form-label">
                          Work Mode *
                        </label>
                        <input
                          type="text"
                          maxLength={25}
                          className="form-control"
                          id="workMode"
                          name="workMode"
                          onChange={jobForm.handleChange}
                          value={jobForm.values.workMode}
                        />
                        {jobForm.touched.workMode && jobForm.errors.workMode ? (
                          <div className="error">{jobForm.errors.workMode}</div>
                        ) : null}
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="budget" className="col-form-label">
                          Budget *
                        </label>
                        <input
                          type="text"
                          maxLength={10}
                          className="form-control"
                          name="budget"
                          id="budget"
                          value={jobForm.values.budget}
                          onChange={jobForm.handleChange}
                        />
                        {jobForm.touched.budget && jobForm.errors.budget ? (
                          <div className="error">{jobForm.errors.budget}</div>
                        ) : null}
                      </div>

                      <div className="col-md-6 form-group mb-3">
                        <label htmlFor="location" className="col-form-label">
                          Location *
                        </label>
                        <input
                          type="text"
                          maxLength={50}
                          className="form-control"
                          name="location"
                          id="location"
                          value={jobForm.values.location}
                          onChange={jobForm.handleChange}
                        />
                        {jobForm.touched.location && jobForm.errors.location ? (
                          <div className="error">{jobForm.errors.location}</div>
                        ) : null}
                      </div>
                      <div className="col-md-6 form-group mb-3">
                        <label
                          htmlFor="country"
                          className="col-form-label d-block"
                        >
                          Country
                        </label>
                        <select
                          className="custom-select w-100"
                          id="country"
                          name="country"
                          value={jobForm.values.country}
                          onChange={jobForm.handleChange}
                        >
                          <option defaultValue="">Choose...</option>
                          <option value="ind">Ind</option>
                          <option value="usa">USA Engine</option>
                          <option value="uk">UK</option>
                        </select>
                        {jobForm.touched.country && jobForm.errors.country ? (
                          <div className="error">{jobForm.errors.country}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 form-group mb-3">
                        <label
                          htmlFor="requiredSkills"
                          className="col-form-label"
                        >
                          Required Skills *
                        </label>
                        <textarea
                          maxLength={500}
                          className="form-control"
                          name="requiredSkills"
                          id="requiredSkills"
                          placeholder=""
                          value={jobForm.values.requiredSkills}
                          onChange={jobForm.handleChange}
                        ></textarea>
                        {jobForm.touched.requiredSkills &&
                        jobForm.errors.requiredSkills ? (
                          <div className="error">
                            {jobForm.errors.requiredSkills}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 form-group mb-3">
                        <label htmlFor="rolesAndRes" className="col-form-label">
                          Roles and Responsibilities *
                        </label>
                        <textarea
                          maxLength={5000}
                          className="form-control"
                          name="rolesAndRes"
                          id="rolesAndRes"
                          placeholder=""
                          value={jobForm.values.rolesAndRes}
                          onChange={jobForm.handleChange}
                        ></textarea>
                        {jobForm.touched.rolesAndRes &&
                        jobForm.errors.rolesAndRes ? (
                          <div className="error">
                            {jobForm.errors.rolesAndRes}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 form-group">
                        <input
                          type="submit"
                          className="btn btn-primary rounded-0 py-2 px-4"
                        />
                        <span className="submitting"></span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostJob;

const validate = (values) => {
  const errors = {};
  if (!values.jobTitle) {
    errors.jobTitle = "Required";
  } else if (values.jobTitle.trim().length < 5) {
    errors.jobTitle = "Must be 15 characters or less";
  }

  if (!values.category) {
    errors.category = "Required";
  } else if (values.category.trim().length < 5) {
    errors.category = "Must be 20 characters or less";
  }

  if (!values.yearsOfExp) {
    errors.yearsOfExp = "Required";
  } else if (values.yearsOfExp.trim().length < 0) {
    errors.yearsOfExp = "Must be 1 characters or more";
  }

  if (!values.workMode) {
    errors.workMode = "Required";
  } else if (values.workMode.trim().length < 3) {
    errors.workMode = "Must be 20 characters or less";
  }

  if (!values.budget) {
    errors.budget = "Required";
  } else if (values.budget.trim().length < 3) {
    errors.workMode = "Seems invalid Budegt";
  }

  if (!values.location) {
    errors.location = "Required";
  } else if (values.location.trim().length < 3) {
    errors.location = "Seems invalid location";
  }

  if (!values.country) {
    errors.country = "Required";
  } else if (values.country.trim().length < 3) {
    errors.country = "Must be 20 characters or more";
  }

  if (!values.requiredSkills) {
    errors.requiredSkills = "Required";
  } else if (values.requiredSkills.trim().length < 20) {
    errors.requiredSkills = "Must be 20 characters or more";
  }

  if (!values.rolesAndRes) {
    errors.rolesAndRes = "Required";
  } else if (values.rolesAndRes.trim().length < 50) {
    errors.rolesAndRes = "Must be 50 characters or more";
  }

  return errors;
};
