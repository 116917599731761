// Header.js
import React from "react";
import { Link } from "react-router-dom";
import "./header.css";
import logo from "./../../assets/img/logo_without_white_circle.svg";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";

const Header = () => {
  return (
    <header>
      <nav className="social-nav">
        <div className="sns-social-links">
          <a
            href="https://www.facebook.com/profile.php?id=100088891546858"
            target="_blank"
            role="button"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook"></i>
          </a>
          <a href="#" role="button">
            <i className="fab fa-instagram"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/sns-it-software-solutions-llp/?viewAsMember=true"
            target="_blank"
            role="button"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="#" role="button">
            <i className="fab fa-twitter"></i>
          </a>
        </div>
      </nav>

      <Navbar collapseOnSelect expand="lg">
        <Navbar.Brand as={Link} to="/">
          <div className="bg"></div>
          <img src={logo} alt="S&S Consultancy Service" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <NavDropdown title="Services" id="collasible-nav-dropdown">
              <NavDropdown.Item href="/#IT_CONSULTING_SERVICES">IT CONSULTING AND SERVICES</NavDropdown.Item>
              <NavDropdown.Item href="/#INTERVIEW_TRAINING">INTERVIEW TRAINING</NavDropdown.Item>
              <NavDropdown.Item href="/#ADVANCE_SOFTWARE_Training">ADVANCE SOFTWARE TRAINING</NavDropdown.Item>
              <NavDropdown.Item href="/#Product_Development">PRODUCT DEVELOPMENT</NavDropdown.Item>
              <NavDropdown.Item href="/#Social_Media_engagement">SOCIAL MEDIA ENGAGEMENT</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={Link} to="/training">
              Training
            </Nav.Link>
            <Nav.Link as={Link} to="/career">
              Career
            </Nav.Link>
            <Nav.Link as={Link} to="/about">
              About Us
            </Nav.Link>
          </Nav>
          <form className="d-flex gap-3 header-search">
            <input
              className="form-control"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <div className="vr"></div>
            <button className="btn btn-primary text-nowrap" type="button">
              Contact Us
            </button>
          </form>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

Header.propTypes = {};

export default Header;
